import React from 'react'
import {useIntl} from "gatsby-plugin-intl"
import {  graphql } from 'gatsby'
import Layout from '../../components/layout'
import Header from '../../components/header'
import OneBlockTwoTextAlt from '../../components/oneBtwoTalt'



const ProductPage = (props) => {

  const intl = useIntl()
  return(
  <Layout>
    
    <Header 
      title={intl.formatMessage({ id: "3ddruck.Dl-UeberSchrift1" })}
      text={intl.formatMessage({ id: "3ddruck.Dl-UeberSchrift2" })}
    />
    
    <div className='row'>
      <div className='col-12'>
      <p> .
      </p>
      </div>
    </div>

      <OneBlockTwoTextAlt
      image={props.data.imageOne}
      title={intl.formatMessage({ id: "3ddruck.Druck-SLA" })}
      /* subtitle= 'K 300-2'  */ 
      text={intl.formatMessage({ id: "3ddruck.Druck-SLAText" })}
      />

      <OneBlockTwoTextAlt
      position= 'right'
      image={props.data.imageTwo}
      title={intl.formatMessage({ id: "3ddruck.Druck-SLS" })}
      col='col-1'
      /* subtitle= 'K 300-4'  */ 
      text={intl.formatMessage({ id: "3ddruck.Druck-SLSText" })}
      />
      <OneBlockTwoTextAlt
      image={props.data.imageThree}
      title={intl.formatMessage({ id: "3ddruck.Druck-FDM" })}
      col='col-2'
      /*subtitle= 'K 300-4'  */ 
      text={intl.formatMessage({ id: "3ddruck.Druck-FDMText" })}
      />
      
  */

    </Layout>
)
  }


export default ProductPage;

//fluidImage is defined on imagePage
export const pageQuery = graphql`
  query {

    imageOne: file(relativePath: { eq: "Moai-200-upper_large-1-1_62.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "002_RGB_Sinterit_Lisa-mod.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "Triprint-DSCN8037-mod.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "foto.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`